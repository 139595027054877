(function () {
  angular.module('7Shop.Core')
    .service('voucherDataService', voucherDataService);

  function voucherDataService(
    uuid,
    LoginData,
    LocalData,
    betshopService
  ) {
    this.getVoucherConfig = function () {
      return {
        requestUuid: uuid.v4(),
        issuer: betshopService.getBetshopId(),
        deliveryPlatform: 'Retail',
        paymentMethod: 'Cash',
        issuerProcessor: {
          type: 'operator',
          uuid: LoginData.user.uuid
        },
        deviceUuid: LocalData.device.uuid,
        loyaltyCard: null,
        totalAmount: 0,
        /**
         *
         * @param {Number} amount
         */
        setAmount: function (amount) {
          this.totalAmount = amount;
        },
        /**
         *
         * @returns {Number}
         */
        getAmount: function () {
          return this.totalAmount;
        },
        appendLoyalty: function (loyaltyData) {
          this.loyaltyCard = loyaltyData;
        },
        ticketPin: null,
        setLocalPin: function (pin) {
          this.ticketPin = pin;
        }
      };
    };

    this.getPayoutVoucherConfig = function () {
      return {
        requestUuid: uuid.v4(),
        user: betshopService.getBetshopId(),
        deliveryPlatform: 'Retail',
        paymentMethod: 'Cash',
        userProcessor: {
          type: 'operator',
          uuid: LoginData.user.uuid
        },
        deviceUuid: LocalData.device.uuid,
        /**
         *
         * @param {String} id
         */
        setId: function (id) {
          this.id = id;
        },

        /**
         *
         * @returns {String}
         */
        getId: function () {
          return this.id;
        },
        /**
         *
         * @param {Number} amount
         */
        setAmount: function (amount) {
          this.totalAmount = amount;
        },
        /**
         *
         * @returns {Number}
         */
        getAmount: function () {
          return this.totalAmount;
        },
        appendLoyalty: function (loyaltyData) {
          this.loyaltyCard = loyaltyData;
        },
        ticketPin: null,
        setLocalPin: function (pin) {
          this.ticketPin = pin;
        }
      };
    };
  }
})();
