/* @ngInject */
function winningTicketsInterceptor(LocalData) {
  /**
     * @param {string} url
     * @returns {boolean}
     */
  const isValidUrl = (url) => url.includes(process.env.GAMES_READ_API);

  /**
     * @param {Object} request
     */
  const addAuthToken = (request) => {
    if (sessionStorage.shopToken) {
      request.headers.Authorization = `Bearer ${sessionStorage.shopToken}`;
    }
  };

  const addTenantInfo = (request) => {
    request.headers['X-Nsft-Games-Tenant-Id'] = LocalData.betshop.companyUuid;
  };

  return {
    request(requestConfig) {
      if (isValidUrl(requestConfig.url)) {
        addAuthToken(requestConfig);
        addTenantInfo(requestConfig);
      }

      return requestConfig;
    }
  };
}

export default winningTicketsInterceptor;
