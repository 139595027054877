import { findWhere } from 'underscore';
import PubSub from 'pubsub-js';

(function () {
  angular.module('7Shop.Core')
    .service('GeneralModulesService', GeneralModulesService);

  function GeneralModulesService(
    $rootScope,
    $state,
    $log,
    $filter,
    SevenClientCoreSvc,
    nabTrans,
    SevenGamesSvc,
    OverlaySvc,
    switchView,
    Shortcuts,
    Modules,
    googleAnalyticsService,
    GravityMenus
  ) {
    var modules = [];
    var deregister;

    function init() {
      const lazyLoadableModules = Modules.getModulesByPosition('onGeneralModulesInit');
      modules = SevenClientCoreSvc.getGeneralModules();

      modules.push({
        id: 'PrintOptions',
        translation: nabTrans.translate('main.offer_results_print', {}, true)
      });
      handleCustomTabModules();

      modules.forEach(function (module) {
        var doLoad = findWhere(lazyLoadableModules, { id: module.id });
        if (doLoad) {
          Modules.loadModule(module.id);
        }
        PubSub.subscribe(`7S:KeyboardEvent.${module.id}`, (e, data) => {
          // Alt keys are used as general modules shortcuts
          handleModuleShortcut(data.code);
        });
      });

      const isWithdrawalEnabled = findWhere(modules, { id: 'Withdrawals' });
      if (isWithdrawalEnabled) {
        handleWithdrawalQRCodeScan();
      }
    }

    function handleWithdrawalQRCodeScan() {
      $rootScope.$on('7S:Scanner.ScanningFinished', function (e, scanData) {
        const enteredCode = scanData.data?.code;
        const isWithdrawalCode = enteredCode && enteredCode.startsWith('XX');

        if (isWithdrawalCode) {
          Modules.loadModule('Withdrawals')
            .then(() => {
              $log.info('[7Shop.generalModulesService] Detected withdrawal code, scanning', {
                code: 'S_MODULES_WITHDRAWAL_CODE_DETECTED',
                details: {
                  enteredCode
                }
              });
              $state.go(
                'app.home.Withdrawals.WithdrawalsProcessing',
                { withdrawalCode: enteredCode },
                { reload: 'app.home.Withdrawals.WithdrawalsProcessing' }
              );
            });
        }
      });
    }

    function handleModuleShortcut(shortcut) {
      var module = $filter('filter')(
        modules,
        { shortcut: shortcut.toUpperCase() },
        true
      );

      // If it is not one of pages (modules)
      if (module.length) {
        googleAnalyticsService.trackEvent('Open_Module', {
          target: 'Shortcut',
          module: module[0].id
        });
        // First close any existing overlay
        OverlaySvc.close();
        if ($state.is('app.home.' + module[0].id)) {
          switchView.selectService(SevenGamesSvc.activeGame).catch((err) => {
            $log.error('[7Shop.Core] Change state module shortcut', {
              code: 'S_ERROR_MODULE_SHORTCUT_STATE_CHANGE',
              upstream_message: err.message,
              upstream_code: err.type,
              details: err.details
            });
          });
        } else {
          Modules.loadModule(module[0].id).then(function () {
            if (!$state.includes('app.home.' + module[0].id)) {
              $state.go('app.home.' + module[0].id);
            }
          });
        }
      }
    }

    /**
       * Set shortcuts for active general modules
       * Update shortcuts in allModulesList necessary for main overlay menu
       */
    function setGeneralModulesShortcuts() {
      const generalModulesShortcuts = Shortcuts.getShortcutByCategory('shop');

      generalModulesShortcuts.forEach(function (generalShortcut) {
        const module = modules.find(({ id }) => id === generalShortcut.action);

        if (module) {
          // Set it explicitly to true because if module was deactivated it would
          // set active as false and shortcut would be deactivated even after logout/login
          // because we keep reference on shortcuts from NabENV and NabENV is not updated after logout/login
          // This problem will gone with T37566
          generalShortcut.active = true;
          const updatedShortcut = Shortcuts.updateShortcut({
            action: module.id,
            name: nabTrans.translate('main.toggle_with_value', { value: module.translation }, true)
          });

          module.shortcut = updatedShortcut.shortcut;
        }
      });
    }

    function getCustomTabs() {
      const megaMenu = GravityMenus.getMenu('megaMenu');
      return megaMenu.filter((menu) => menu.customData?.customTab);
    }

    function handleCustomTabModules() {
      const customTabs = getCustomTabs();
      customTabs?.forEach(({ customData }) => {
        modules.push({
          className: customData?.iconName,
          id: customData?.moduleId,
          shortcut: customData?.shortcut,
          translation: customData?.translation,
          type: 'general',
          visible: true
        });
      });
    }

    deregister = $rootScope.$on('SevenClientCore:ready', function () {
      init();
      setGeneralModulesShortcuts();
      deregister();
    });

    return {
      activeModule: {},
      setActiveModule: function (moduleId) {
        this.activeModule = $filter('filter')(modules, { id: moduleId }, true)[0];
        return this.activeModule;
      }
    };
  }
})();
